import React, { useEffect } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

export default function Home({ data }) {

useEffect(() => {

  document.getElementById('music').addEventListener('mouseover', function(){
    const el = document.getElementById('music-img');
    el.classList.add("show");
  });

  document.getElementById('music').addEventListener('mouseout', function(){
    const el = document.getElementById('music-img');
    el.classList.remove("show");
  });

  document.getElementById('landscape').addEventListener('mouseover', function(){
    const el = document.getElementById('landscape-img');
    el.classList.add("show");
  });

  document.getElementById('landscape').addEventListener('mouseout', function(){
    const el = document.getElementById('landscape-img');
    el.classList.remove("show");
  });

  document.getElementById('event').addEventListener('mouseover', function(){
    const el = document.getElementById('event-img');
    el.classList.add("show");
  });

  document.getElementById('event').addEventListener('mouseout', function(){
    const el = document.getElementById('event-img');
    el.classList.remove("show");
  });
  
  gsap.from(".faded", {
    immediateRender: false,
    autoAlpha: 0,
    opacity: 0,
    duration: 0.5,
    y: 40,
    stagger: 0.15,
    delay: 0.2
  });

  const boxes = gsap.utils.toArray('.home-image');
    boxes.forEach((box, i) => {
      const anim = gsap.fromTo(box, {
        immediateRender: false,
          autoAlpha: 0,
          y: 50,
          stagger: 0.20,
          ease: "power1.in"
        }, {
          duration: 1,
          autoAlpha: 1,
          y: 0
      });
      ScrollTrigger.create({
        trigger: box,
        animation: anim,
        toggleActions: 'play none none none',
      });
    });
  }, []);

  return (
      
    <Layout isHomePage>
      <Seo title="Chad Rieder | Minneapolis Music Photographer" description="Chad Rieder is a landscape, event, and music photographer based in Minneapolis, MN. Check out his galleries and contact for licensing or availability." />
      <div className="home-inner">
        <section className="home-intro">
          <div className="max-inner">
            <div className="home-intro-inner">
              <div className="home-hdg-wrap">
                <h1 className="home-hdg faded">
                  Chad Rieder
                </h1>
                </div>
                <div className="home-intro-img-wrap faded">
                  <div className="home-intro-img">
                    <StaticImage 
                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1702228235/landscape/hoh-rainforest-waterfall-olympic.jpg"
                    alt="Hoh Rainforest Olympic National Park"
                    loading="eager" 
                    placeholder="blurred"
                    className="hero-img"
                    />
                  </div>
                  <div id="music-img" className="home-intro-img secondary">
                    <StaticImage 
                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1650/v1567359029/home/taylor-hawkins-foo-fighters.jpg"
                    alt="Taylor Hawkins Foo Fighters"
                    className="hero-img"
                    />
                  </div>
                  <div id="landscape-img" className="home-intro-img secondary">
                    <StaticImage 
                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1650/v1639350388/home/grand-canyon-star-trails.jpg"
                    alt="Grand Canyon star trails"
                    className="hero-img"
                    />
                  </div>
                  <div id="event-img" className="home-intro-img secondary">
                    <StaticImage 
                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1650/v1629214713/home/minneapolis-event-photographer.jpg"
                    alt="Minneapolis Event Photographer"
                    className="hero-img"
                    />
                  </div>
                </div>
                <div className="port-links">
                  <h2 className="port-hdg serif-hdg faded">Portfolios</h2>
                  <ul className="port-list">
                    <li className="faded">
                      <Link to="/music/" id="music">Music</Link>
                    </li>
                    <li className="faded">
                      <Link to="/landscape/" id="landscape">Landscape</Link>
                    </li>
                    <li className="faded">
                      <Link to="/event/" id="event">Event</Link>
                    </li>
                  </ul>
                </div>
              </div>
          </div>
        </section>

        <section className="home-images">
          <div className="home-images-flex max-inner">
          <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1649519123/music/khruangbin-live.jpg"
              alt="Khruangbin live"
              placeholder="blurred"
              />
            </div>
            
            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1702226239/landscape/clearwater-lake-bwca.jpg"
              alt="Clearwater Lake BWCA"
              placeholder="blurred"
              />
            </div>

            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1619984962/music/interpol-band-live.jpg"
              alt="Interpol Palace Theatre St. Paul"
              placeholder="blurred"
              />
            </div>
            
            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1573422868/landscape/badlands-national-park-bison.jpg"
              alt="Badlands bison"
              placeholder="blurred"
              />
            </div>

            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1620336200/music/chvrches-basilica-minneapolis.jpg"
              alt="Chvrches Minneapolis"
              placeholder="blurred"
              />
            </div>

            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1534607253/landscape/glacier-point-yosemite-national-park.jpg"
              alt="Yosemite National Park"
              placeholder="blurred"
              />
            </div>
          </div>

          <div className="home-image full-width">
            <div className="max-inner">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1620336203/music/dave-grohl-sweat-foo-fighters.jpg"
              alt="Dave Grohl Foo Fighters"
              placeholder="blurred"
              />
            </div>
          </div>

          <div className="home-images-flex bottom max-inner">
            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1619986619/landscape/tettegouche-island-sea-stack.jpg"
              alt="Tettegouche island sea stack"
              placeholder="blurred"
              />
            </div>

            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1638030713/music/my-morning-jacket-carl-broemel.jpg"
              alt="Carl Broemel My Morning Jacket"
              placeholder="blurred"
              />
            </div>

            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1623355630/landscape/deschutes-stars-oregon.jpg"
              alt="Deschutes Oregon stars"
              placeholder="blurred"
              />
            </div>

            <div className="home-image">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1631916313/music/phoebe-bridgers.jpg"
              alt="Phoebe Bridgers"
              placeholder="blurred"
              />
            </div>

            <div className="home-image">
                <StaticImage 
                src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1649517967/landscape/humboldt-redwoods-state-park.jpg"
                alt="Humboldt Redwoods State Park"
                placeholder="blurred"
                />
            </div>

            <div className="home-image">
              <StaticImage 
               src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1382/v1638031220/music/my-morning-jacket-live.jpg"
               alt="My Morning Jacket live"
               placeholder="blurred"
              />
            </div>
          </div>

          <div className="home-image full-width">
            <div className="max-inner">
              <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1534689145/landscape/kawishiwi-lake-bwca.jpg"
              alt="Kawishiwi Lake BWCA"
              placeholder="blurred"
              />
            </div>
          </div>

        </section>
        
      </div>
    </Layout>
  )
}
